import { useEffect } from "react";
import LoadingScreen from "./LoadingScreen";
import { ISdkEnabledComponentProps } from "../SimulationSDK";

interface IConfigWrapperProps {
  app: React.ReactElement<ISdkEnabledComponentProps>;
}

function ConfigWrapper({ app }: IConfigWrapperProps) {
  useEffect(() => {
    app.props.sdk.initialize()    
  }, []);
  
  return (
    <>
      {app.props.sdk.initialized && app}
      {app.props.sdk.loading && <LoadingScreen />}
    </>
  );
}

export default ConfigWrapper;

import { Box, Button, Checkbox, Chip, Drawer, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Investor, Simulation } from "../../model/Simulation";
import React from "react";
import { formatInvestmentAmount, getAge } from "../../utils";

interface IInvestorsProps {
  simulation: Simulation | null;
  onSimulationChange: (simulation: Simulation) => void;
}

const Investors: React.FC<IInvestorsProps> = ({ simulation, onSimulationChange }) => {
    const [showInvestors, setShowInvestors] = React.useState<boolean>(false);
    const label = simulation===null?"": "(" + simulation?.investors.filter(i => i.active).length + " / " + simulation?.investors.length + ")";

    const handleInvestorChange = (investor: Investor) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSimulation = {...simulation} as Simulation;
        const newInvestor = newSimulation?.investors?.find(i => i.id === investor.id);
        if (newInvestor) {
          newInvestor.active = event.target.checked;
          onSimulationChange(newSimulation);
        }
      }

    return <>    
        <Drawer open={showInvestors} onClose={()=>setShowInvestors(false)}>
          <Box position="relative" sx={{ position: 'absolute', top: 0, right: 0, m: 2 }}>
            <Button variant="contained"  onClick={() => setShowInvestors(false)}>Close</Button>
          </Box>
          <Typography variant="h5" p={2}>Investors</Typography>
          <Grid p={2}>
            <Box width="50vw" minWidth={600}>
              <Grid container size={12} columns={12}>
                <Grid size={1} textAlign="center">Active</Grid>
                <Grid size={6} textAlign="center">Name</Grid>                
                <Grid size={5} textAlign="center">Investments</Grid>
                {simulation?.investors.map((investor, index) => {
                  const backgroundColor = index % 2 === 0 ? "white" : "whitesmoke";
                  const styles = {backgroundColor: backgroundColor, padding: 1, margin: 0};
                  return <React.Fragment key={investor.id}>
                    <Grid sx={styles} size={1} textAlign="center">
                      <Checkbox defaultChecked={investor.active} onChange={handleInvestorChange(investor)} size="small" sx={{p:0}} />
                    </Grid>
                    <Grid sx={styles} size={4} textAlign="center">
                      <Stack direction="row" alignItems="center">
                        <Typography variant="body1">{investor.name}</Typography>
                        <Typography variant="body2" paddingLeft={2}>{investor.birthDate}</Typography>
                        <Chip label={getAge(investor.birthDate) + " yo"} variant="outlined"  size="small" sx={{ marginLeft:1}}/>
                      </Stack>
                    </Grid>
                    <Grid sx={styles} size={7}>
                      {investor.investments.sort((a, b) => (b?.amount?.value??0) - (a?.amount?.value??0)).map((investment, index) =>
                        <Typography component="span" key={investment.id} p={1} noWrap>
                          {investment.name} 
                          <Chip label={formatInvestmentAmount(investment)} color={investment.account==null?"primary":"success"} size="small" sx={{marginLeft:1}} />
                        </Typography>
                      )}
                    </Grid>                    
                  </React.Fragment>
                }
                )}
              </Grid>
            </Box>
          </Grid>
        </Drawer>

        <Button variant="outlined" disabled={!simulation} onClick={()=>setShowInvestors(true)} >Investors {label}</Button>
    </>;
};

export default Investors;
import { Simulation } from "./model/Simulation";
import { SimulationResult } from "./model/SimulationResult";
import { Summary } from "./model/Account";
import { resolveReferences, serializeReferences } from "./utils";
import { useState } from 'react';

export interface ISettings {
    version: string;
    apiversion: string,
    url_idp: string;
    url_api: string;
    idp_realm: string;
    idp_clientid: string;
}

export interface ISdkEnabledComponentProps extends React.PropsWithChildren<{}> {
  sdk: SimulationSDK;
}

export interface SimulationSDK {
  settings: ISettings | null;
  loading: boolean;
  unauthenticated: boolean;
  initialized: boolean;
  initialize: () => Promise<void>;
  fetchSummary: () => Promise<Summary>;
  fetchSimulation: (simulationId: string) => Promise<Simulation>;
  runSimulation: (simulation: Simulation) => Promise<SimulationResult>;
  saveSimulation: (simulation: Simulation) => Promise<Simulation | null>;  
}

export const useSimulationSDK = (): SimulationSDK => {
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [unauthenticated, setUnauthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
    const [initialized, setInitialized] = useState<boolean>(false);

  const initialize = async () => {
    const settingsData = await fetchSettings();
    setSettings(settingsData);
  };

  const fetchSettings = async (): Promise<ISettings> => {
    setLoading(true);    
    const response = await fetch('/settings.json');
    const data = await response.json();

    const gresponse = await fetch(data.url_api + '/api/version', { mode: 'cors' });
    const gversion = (await gresponse.json()).version;

    setLoading(false);
    setInitialized(true);
    return {
      version: data.version,
      apiversion: gversion,
      url_idp: data.url_idp,
      url_api: data.url_api,
      idp_realm: data.idp_realm,
      idp_clientid: data.idp_clientid,
    };
  };

  const fetchSummary = async (): Promise<Summary> => {
    setLoading(true);
    if (!settings) throw new Error('SDK not initialized');
    const response = await fetch(`${settings.url_api}/api/account/summary`);

    if (!response.ok) {
      if (response.status === 401) {
        setUnauthenticated(true);
      }
      setLoading(false);
      throw new Error(`Failed to fetch accounts: ${response.statusText}`);
    }
    setLoading(false);
    return response.json() as Promise<Summary>;
  };

  const fetchSimulation = async (simulationId: string): Promise<Simulation> => {
    if (!settings) throw new Error('SDK not initialized');
    setLoading(true);
    const response = await fetch(`${settings.url_api}/api/Simulation/${simulationId}`);
    if (!response.ok) {
        if (response.status === 401) {
            setUnauthenticated(true);
          }
          setLoading(false);
      throw new Error(`Failed to fetch simulation: ${response.statusText}`);
    }
    const json = await response.json();
    setLoading(false);
    return resolveReferences(json);
  };

  const runSimulation = async (simulation: Simulation): Promise<SimulationResult> => {
    if (!settings) throw new Error('SDK not initialized');
    setLoading(true);
    const response = await fetch(`${settings.url_api}/api/Simulation/run`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(serializeReferences(simulation)),
    });
    if (!response.ok) {
        if (response.status === 401) {
            setUnauthenticated(true);
        }
        setLoading(false);
      throw new Error(`Failed to run simulation: ${response.statusText}`);
    }
    const json = await response.json();
    setLoading(false);
    return resolveReferences(json);
  };

  const saveSimulation = async (simulation: Simulation): Promise<Simulation | null> => {
    setLoading(true);
    if (!settings) throw new Error('SDK not initialized');
    const response = await fetch(`${settings.url_api}/api/Simulation/${simulation.uId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(serializeReferences(simulation)),
    });
    setLoading(false);
    if (response.status === 401) {
      setUnauthenticated(true);
    }
    if (response.status === 403) {
      return null;
    }
    if(response.ok) {
      const json = await response.json();
      return resolveReferences(json);
    }
    return null;
  };

  return {
    settings,
    initialized,
    loading,
    unauthenticated,
    initialize,
    fetchSummary,
    fetchSimulation,
    runSimulation,
    saveSimulation,
  };
};

import React from 'react';
import { ISdkEnabledComponentProps } from '../../SimulationSDK';

interface ISimulatorProps extends ISdkEnabledComponentProps {
}


const Simulator = ({sdk}: ISimulatorProps) => {
  const [showLoader, setShowLoader] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return showLoader ? (
    <div className="loader-container">
        FOREX
      <div className="loader">
        <div className="inner one"></div>
        <div className="inner two"></div>
        <div className="inner three"></div>
      </div>
    </div>    
  ) : null;
};

export default Simulator;
import React from 'react';
import { useSimulationSDK } from '../SimulationSDK';
import Simulator from './ForexSimulator/Simulator';
import App from './App';
import ConfigWrapper from './ConfigWrapper';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

const RoutedApp = () => {
    
    const simulationSDK = useSimulationSDK();
    const app = <App sdk={simulationSDK} />;
    const forexsimulator = <Simulator sdk={simulationSDK} />;

    const router = createBrowserRouter([
    {
        path: "/portfoliosimulator",
        element: <ConfigWrapper app={app} />,
    },
    {
        path: "/forexsimulator",
        element: <ConfigWrapper app={forexsimulator} />,
    },
    {
        path: "/",
        element: <Navigate to="/portfoliosimulator" replace />,
    }
    ]);

    return <RouterProvider router={router} />
};

export default RoutedApp;
import React, { useEffect } from 'react';
import { Box, Button, ButtonGroup, Checkbox, FormControlLabel, Link, Stack, Typography } from '@mui/material';
import { Simulation } from '../model/Simulation';
import { SimulationResult } from '../model/SimulationResult';
import { BarTooltipProps, ResponsiveBar  } from '@nivo/bar'
import { ISdkEnabledComponentProps } from '../SimulationSDK';
import { formatCurrency } from '../utils';
import { Settings, Investors, LifeEvents, Needs, ButtonExt, LoadScenario } from './Components';
import Grid from '@mui/material/Grid2';
import './App.css';
import { Public as PublicIcon, Lock as LockIcon } from '@mui/icons-material';

interface IAppProps extends ISdkEnabledComponentProps {
  
}

function App({sdk}: IAppProps) {
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [currentUid, setCurrentUid] = React.useState<string | null>(window.location.hash?.substring(1));
  const [simulation, setSimulation] = React.useState<Simulation | null>(null);
  const [simulationResult, setSimulationResult] = React.useState<SimulationResult | null>(null);
  const [cashflows, setCashflows] = React.useState<any[]>([]);
  const [cashflowsInPresentValue, setCashflowsInPresentValue] = React.useState<any[]>([]);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [isAnimated, setIsAnimated] = React.useState<boolean>(false);
  const [intervalId, setIntervalId] = React.useState<number>(0);
  const [showSettings, setShowSettings] = React.useState<boolean>(false);
  var values = Array.from({ length: 25 }, (_, i) => ({
    date: (new Date().getFullYear() + i).toString(),
    amount: 0,
    investmentRemaining: 0,
    interest: 0,
    withdrawal: 0,
    revenue: 0,
    allowance: 0
  }));

  const loadSimulationByUId = (uid: string) => {
    console.debug(`Loading simulation ${uid}`);
    window.location.hash = uid;
    setCurrentUid(uid);        
  }

  const loadSimulation = async () => {
    if(!sdk.initialized) return;
    try {
      if(!currentUid) return;
      const simulation = await sdk.fetchSimulation(currentUid); 
      console.debug(`Simulation ${simulation.uId} loaded`);
      if(!simulation) {        
        setCurrentUid(null);
        return;
      }
      setSimulation(simulation);      
    } catch (error) {
      setCurrentUid(null);
      console.error('Error fetching data:', error);
    }
  }
    
  useEffect(() => {
    (async () => {
      if(!sdk.initialized) return;
      if(!currentUid){
        setInitialized(true);
        return;
      }
      try {
        const simulation = await sdk.fetchSimulation(currentUid); 
        if(!simulation) {        
          setCurrentUid(null);
          return;
        }
        setSimulation(simulation);
        setInitialized(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    })();
  }, [sdk.initialized, currentUid]);

  useEffect(() => {
    if (simulation) {
      console.debug(`Simulation ${simulation.uId} loaded`);
      setIsAnimated(false);
      if (currentUid !== simulation.uId) {
        window.location.hash = simulation.uId;
        console.debug(`Moving to new simulation since ${currentUid} !== ${simulation.uId}`);
        return;
      }
      
      ( async () => {
        const summary = await sdk.runSimulation(simulation);
        setSimulationResult(summary);

        setCashflows(summary.cashflows.map(cashflow => ({
            date: cashflow.date.substring(0, 4),
            amount: cashflow.amount,
            investmentRemaining: cashflow.investmentRemaining,
            interest: cashflow.interest,
            withdrawal: cashflow.withdrawal,
            revenue: cashflow.revenue,
            allowance: cashflow.allowance,
          })));

        setCashflowsInPresentValue(summary.cashflowsPresentValue.map(cashflow => ({
          date: cashflow.date.substring(0, 4),
          amount: cashflow.amount,
          investmentRemaining: cashflow.investmentRemaining,
          interest: cashflow.interest,
          withdrawal: cashflow.withdrawal,
          revenue: cashflow.revenue,
          allowance: cashflow.allowance,
        })));
      })();
    }else{
      setIsAnimated(true);
    }
  }, [simulation]);
  
  useEffect(() => {    
    
    if(!isAnimated) {
      console.debug(`Stopping animation ${intervalId}`);
      clearInterval(intervalId);
    }else{
      console.debug('Starting animation');
      let time = 0;

      clearInterval(intervalId);
      const animate = () => {        
        const newValues = [] as any[];
          values.forEach((v, i) => {
              const waveValue = Math.sin((i + time) * (2 * Math.PI / values.length)) * 500 + 500;        
              Object.assign(v, {
                  amount: waveValue,
                  interest: waveValue * 100,
                  investmentRemaining: waveValue * 500,
                  withdrawal: waveValue * 200,
                  revenue: waveValue * 100,
                  allowance: waveValue * 200
                });
                newValues.push(v);
            });

          setCashflows(newValues);
          setCashflowsInPresentValue(newValues);
          time += .25;
        };

      setIntervalId(window.setInterval(animate, 100));
      console.debug(`Animation started ${intervalId}`);
    }
  }, [isAnimated]);

  const saveSimulation = async () => {
    if(simulation) {
      const newSimulation = await sdk.saveSimulation(simulation);
      setIsDirty(false);
      setSimulationResult(null);      
      setSimulation(newSimulation);      
    }
  }

  const onSimulationChange = (simulation: Simulation) => {
    setSimulation(simulation);
    setIsDirty(true);
  }

  const handleIsPublicChanged = async () => {
    if(simulation) {
      simulation.isPublic = !simulation.isPublic;
      const newSimulation = await sdk.saveSimulation(simulation);
      setSimulation(newSimulation);
    }
  }

  const handleNew = () => {
    
  }

  const CustomTooltip = ({ id, value, color, indexValue }: BarTooltipProps<any>) => {

    const idToNameMaps: { [key: string]: string } = {
        'amount': 'Salary',
        'investmentRemaining': 'Investment Remaining',
        'interest': 'Interest',
        'withdrawal': 'Withdrawal',
        'revenue': 'Revenue',
        'allowance': 'Allowance',
    };

    return (
      <Box
          style={{
              padding: '12px',
              background: 'white',
              border: `1px solid ${color}`,
              borderRadius: '4px',
              color: '#333',
          }}
      >
          <strong>{idToNameMaps[id]}</strong>: ${value.toLocaleString()}<br />
          Date: {indexValue}
      </Box>
    );
  }

  const handleNameChange = () => {
    setShowSettings(true);
  }

  const handleClearSimulation = () => {
    setSimulation(null);
    setSimulationResult(null);
    setIsDirty(false);
    window.location.hash = "";
  }

  return (
    <div className="App">
      <Grid container spacing={1} padding={1}>
        <Typography variant="h5">
          {simulation&&<Link onClick={handleNameChange} component="button">{simulation.name}</Link>}
          {!simulation&&"No simulation"}
        </Typography>

        <LoadScenario open={!simulation && initialized} onNew={handleNew} onClose={loadSimulationByUId} />

        <Button variant="text" disabled> {formatCurrency(simulationResult?.initialTotalAmount)}</Button>
        <ButtonGroup>
          <Investors simulation={simulation} onSimulationChange={onSimulationChange} />
          <Needs simulation={simulation} onSimulationChange={onSimulationChange} />
          <LifeEvents simulation={simulation} onSimulationChange={onSimulationChange} />
          <ButtonExt variant="outlined" onClick={loadSimulation} disabled={!currentUid} executingLabel="Loading...">{currentUid?"Reload":"Load"}</ButtonExt>
          <ButtonExt variant="contained" onClick={()=>saveSimulation()} disabled={(!isDirty && !!simulation) || !simulation} executingLabel="Saving...">Save</ButtonExt>
          <Settings simulation={simulation} onSimulationChange={onSimulationChange} show={showSettings} onClose={()=>setShowSettings(false)} />
        </ButtonGroup>
        <ButtonExt variant="text" onClick={handleIsPublicChanged} color={simulation?.isPublic?"warning":"info"} disabled={!simulation} startIcon={(simulation?.isPublic)?<PublicIcon/>:<LockIcon/>}>{(simulation?.isPublic)?"Public":"Private"}</ButtonExt>
        <ButtonExt variant="text" onClick={handleClearSimulation} color="info" disabled={!simulation}>Close</ButtonExt>

        <Grid size={12}>
          <Box sx={{ p:3 }} style={{ height: 300 }}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h6">Income</Typography>
              <FormControlLabel label="Present Value" control={
                <Checkbox 
                  checked={!!simulation?.isIncomeInPresentValue} 
                  onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{if(simulation) {simulation.isIncomeInPresentValue = e.target.checked; onSimulationChange({...simulation});}}}
                  size="small" sx={{p:0}}
                  disabled={!simulation}
                />}                
              />
            </Stack>
            <ResponsiveBar            
                    data={simulation?.isIncomeInPresentValue?cashflowsInPresentValue:cashflows}
                    keys={['withdrawal', 'revenue', 'allowance']}                
                    tooltip={CustomTooltip}
                    indexBy="date"
                    margin={{ top: 10, right: 130, bottom: 20, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={ simulation? ['#FFA726','#64B5F6', '#81C784']: ['#808080', '#a6a6a6', '#eeeeee'] }
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                        format: '.2s',
                    }}
                    valueFormat=" >($.2s"
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                    role="application"
                />
          </Box>
          <Box sx={{ p:3 }} style={{ height: 300 }}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h6">Investments</Typography>
                <FormControlLabel label="Present Value" control={
                  <Checkbox 
                    checked={!!simulation?.isInvestmentInPresentValue} 
                    onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{if(simulation) {simulation.isInvestmentInPresentValue = e.target.checked; onSimulationChange({...simulation});}}}
                    size="small" sx={{p:0}}
                    disabled={!simulation}
                  />}
                />
            </Stack>
            <ResponsiveBar
                    data={simulation?.isInvestmentInPresentValue?cashflowsInPresentValue:cashflows}
                    keys={['investmentRemaining', 'interest']}                
                    tooltip={CustomTooltip}
                    indexBy="date"
                    margin={{ top: 10, right: 130, bottom: 20, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={ simulation? ['#66BB6A','#FFD700'] : ['#a6a6a6', '#eeeeee'] }
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                        format: '.2s',
                    }}
                    valueFormat=" >($.2s"
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                    role="application"
                    ariaLabel="Financial summary bar chart"
                    barAriaLabel={function(e) {
                        return e.id + ': ' + e.formattedValue + ' on ' + e.indexValue;
                    }}
                />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;

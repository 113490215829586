import React, { useState } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface ButtonExtProps extends ButtonProps {
  loading?: boolean;
  executingLabel?: string;
}

const ButtonExt = React.forwardRef<HTMLButtonElement, ButtonExtProps>(
  ({ loading: loadingProp, onClick, startIcon, executingLabel, children, ...props }, ref) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!loadingProp) {
        setLoading(true);
      }
      if (onClick) {
        await onClick(event); // Await the onClick function passed via props
      }
      if (!loadingProp) {
        setLoading(false);
      }
    };

    const isLoading = loadingProp ?? loading;

    return (
      <Button
        ref={ref}
        {...props}
        onClick={handleClick}
        disabled={isLoading || props.disabled}
        startIcon={isLoading ? <CircularProgress size={20} /> : startIcon}
      >
        {isLoading ? executingLabel??"Loading..." : children}
      </Button>
    );
  }
);

export default ButtonExt;
